.App {
	text-align: center;
	width: 100vw;
	margin-top: 0;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
}

.easter-div {
	float: right;
	margin-left: auto;
	right: 0;
	margin-top: -20px;
	width: 50px;
	height: 50px;
	position: absolute;
	z-index: 5;
}

.easter-img {
	width: 300px;
	display: none;
	position: absolute;
	top: 0;
	right: 0;
	box-shadow: 0px 0px 5px 1px rgba(0, 0, 0);
	z-index: 4;
}

.easter-div:hover + .easter-img {
	display: block;
}

.App > .loader {
	margin-top: 20%;
}
