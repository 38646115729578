.premium-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 50px 0;
	padding: 40px;
	margin: 100px 10% 0;
	background-color: #ffffff;
	box-shadow: 2px 4px 4px 3px rgba(0, 0, 0, 0.12);
	border-radius: 10px;
	animation: fadeIn 0.4s ease-out;
}

.premium-card h1 {
	font-size: 30px;
	font-weight: 500;
	opacity: 0.9;
	margin: 0;
}

.card-body {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 600px;
}
.card-body img {
	width: 40%;
	height: 40%;
}

.premium-btn {
	border-radius: 20px;
	border: 1px solid var(--primary);
	background-color: var(--primary);
	color: #ffffff;
	font-size: 15px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
	transition: transform 80ms ease-in;
	width: 100%;
}
.premium-btn:hover {
	transform: scale(0.98);
}

.content-message {
	padding: 30px 20px;
	margin: 100px 20% 0;
	text-align: center;
	background-color: #ffffff;
	border-radius: 10px;
	box-shadow: 2px 4px 4px 3px rgba(0, 0, 0, 0.12);
	font-weight: bold;
	font-size: 24px;
	color: #333333;
}

.content-message.error {
	background-color: #ffebee;
}

.credit-text {
	max-width: 300px;
	font-size: 20px;
	font-weight: 400;
}
.credit-count {
	font-size: 25px;
	font-weight: 700;
	color: var(--primary);
}

.content-action .btn {
	background-color: #505f98;
	color: #ffffff;
	padding: 20px 40px;
	border-radius: 30px;
	cursor: pointer;
	transition: background-color 0.3s ease;
	box-shadow: none;
}

.content-action .btn:hover {
	background-color: #37436f;
}

.premium-btn:disabled {
	opacity: 0.3;
}
.premium-btn:disabled:hover {
	transform: scale(1);
}

@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translateY(50px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}
