.playlist-page {
	width: 100%;
	display: flex;
	flex-flow: column;
	align-items: center;
}
.playlist-info {
	display: flex;
	flex-flow: row;
	align-items: center;
	justify-content: start;
	margin: 0 10% 20px;
	width: 40dvw;
	border-radius: 10px;
	background-color: #f5f5f5;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	padding: 20px 40px;
	border-radius: 20px;
	min-height: 120px;
}
.playlist-info button {
	cursor: pointer;
}
.playlist-info img {
	height: 100px;
	border-radius: 10px;
	object-fit: cover;
	margin-right: 20px;
}
.playlist-info--body {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-grow: 1;
}
.playlist-info--body h3 {
	margin: 0;
}
.download-div {
	height: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
}

.downloading-content {
	width: 100%;
}
.downloading-content p {
	margin-left: 20px;
	font-weight: 400;
}
