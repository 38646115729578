@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
:root {
	--main-bg-color: #f0f0f0;
	--primary: #2a2adc;
	--secondary: #d437d6;
}

@font-face {
	font-family: 'MundialRegular';
	src:
		local('MundialRegular'),
		url('../fonts/MundialRegular.otf') format('opentype');
}
@font-face {
	font-family: 'MundialLight';
	src:
		local('MundialLight'),
		url('../fonts/MundialLight.otf') format('opentype');
}

body {
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: var(--main-bg-color);
	margin: 0;
}

a {
	text-decoration: none;
}

@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translateY(10px);
	}

	to {
		opacity: 1;
		transform: translateY(0px);
	}
}
.btn {
	background-color: var(--primary);
	color: var(--main-bg-color);
	padding: 5px 10px;
	font-weight: bold;
}

.appear-animation {
	opacity: 0;
	transform: translateY(10px);
	animation: fadeIn 0.4s ease-in;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}

button {
	cursor: pointer;
}
