#Recover {
	display: flex;
	justify-content: space-around;
	align-items: center;
	position: relative;
	font-family: 'Montserrat', sans-serif;
	background: #ffffff;
	border-radius: 10px;
	box-shadow:
		0 14px 28px rgba(0, 0, 0, 0.25),
		0 10px 10px rgba(0, 0, 0, 0.22);
	width: 768px;
	margin-top: 100px;
	max-width: 100%;
	min-height: 480px;
}

.recover-form {
	background-color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 50px;
	height: 100%;
	text-align: center;
}

.recover-input {
	background-color: #eee;
	border: none;
	padding: 12px 15px;
	margin: 40px 0;
	width: 80%;
}

.back-link {
	font-size: 12px;
	text-decoration: none;
	width: 100%;
	text-align: left;
	margin-bottom: 50px;
}
.back-link a {
	color: #333;
}

.back-link a:hover {
	text-decoration: underline;
}
