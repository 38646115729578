.Navbar {
	display: flex;
	flex-flow: row;
	justify-content: end;
	align-items: center;
	gap: 50px;
	font-size: 16px;
	margin: 30px 10% 0 10%;
	width: 80%;
}
.Navbar * {
	color: var(--primary);
}

.logo {
	height: 30px;
	font-weight: 1000;
	margin-right: auto;
	margin-left: 0;
}
.logo img {
	height: 100%;
}

.btn {
	background-color: var(--primary);
	color: var(--main-bg-color);
	padding: 5px 10px;
	font-weight: bold;
	border-radius: 4px;
}

.active {
	font-weight: bold;
}
