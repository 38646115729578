.tracks-container {
	width: 90%;
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	gap: 15px;
	margin: 20px auto;
}

.trends-title {
	width: 100%;
}

.trends-container {
	width: 90%;
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	gap: 15px;
	margin: 20px auto;
}

.track-card {
	background-color: white;
	width: 250px;
	height: 250px;
	padding: 20px;
	border-radius: 20px;
	display: flex;
	flex-flow: column;
	justify-content: flex-end;

	-webkit-box-shadow: 2px 4px 4px 3px rgba(0, 0, 0, 0.12);
	-moz-box-shadow: 2px 4px 4px 3px rgba(0, 0, 0, 0.12);
	box-shadow: 2px 4px 4px 3px rgba(0, 0, 0, 0.12);
}
.header {
	display: flex;
	justify-content: flex-start;
	flex-flow: row;
	align-items: start;
	margin-top: -0;
	margin-bottom: auto;
}
.source-icon {
	width: 23px;
	height: 23px;
	border-radius: 3px;
}

.title {
	font-size: 14px;
	margin: 0;
	margin-left: 10px;
	text-align: left;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	line-clamp: 3;
	-webkit-box-orient: vertical;
}

.preview {
	width: 250px;
	height: 140px;
	margin-bottom: 15px;
	border-radius: 4px;
	position: relative;
	overflow: hidden;
}
.preview:hover > .play-button {
	transform: scale(1.15);
	-webkit-filter: drop-shadow(0px 0px 5px #222);
	filter: drop-shadow(0px 0px 5px #222);
}
.thumbnail {
	/* width: 250px; */
	height: 140px;
	border-radius: 4px;
	object-fit: contain;
}
.blurred {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: -1;
	filter: blur(10px);
}
.play-button {
	position: absolute;
	width: 50px;
	top: calc(50% - 25px);
	left: calc(50% - 25px);
	transition: 0.15s ease-in-out all;
}

.footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.footer button {
	cursor: pointer;
}
.quality-selector {
	font-weight: 600;
	font-style: normal;
	font-size: 13px;
	width: 50%;
	height: 30px;
	border: none;
	border-radius: 3px;
	box-shadow: 0 0 1.4px 0 rgba(0, 0, 0, 0.25);
	color: #383b45;
}
.quality-selector:focus {
	outline: none;
}
.quality-selector:disabled {
	opacity: 36%;
}
.download {
	background: none;
	border-radius: 100%;
	border: none;
	height: 30px;
	width: 30px;
	box-shadow:
		inset -0.5px -0.5px 1px 0 var(--primary),
		inset 0.5px 0.5px 1px 0 hotpink;
}
.downloading {
	animation: rotateThis 1s linear infinite;
}
.downloading-reverse {
	animation: rotateReverse 1s linear infinite;
}
@keyframes buttonClick {
	from {
		border: #383b45 2px solid;
	}

	to {
		border: #fff 0px solid;
	}
}

@keyframes rotateThis {
	from {
		transform: rotate(0deg) scale(1);
	}

	to {
		transform: rotate(360deg) scale(1);
	}
}
@keyframes rotateReverse {
	from {
		transform: rotate(0deg) scale(1);
	}

	to {
		transform: rotate(-360deg) scale(1);
	}
}

iframe {
	border: none;
	border-radius: 4px;
	margin-bottom: 15px;
}

select {
	padding-left: 5px;
}
