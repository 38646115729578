.toast {
	position: fixed;
	top: 25px;
	right: 30px;
	border-radius: 12px;
	background: #fff;
	padding: 20px 35px 20px 25px;
	box-shadow: 0 6px 20px -5px rgba(0, 0, 0, 0.1);
	overflow: hidden;
	transform: translateX(calc(100% + 30px));
	transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.35);
	max-width: 40%;
	z-index: 5;
}

.toast.active {
	transform: translateX(0%);
}

.toast .toast-content {
	display: flex;
	align-items: center;
}

.toast-content .icon {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 35px;
	min-width: 35px;
	font-size: 20px;
	border-radius: 50%;
}

.icon.error {
	color: #e74c3c;
}
.icon.success {
	color: #3498db;
}

.toast-content .message {
	display: flex;
	flex-direction: column;
	margin: 0 20px;
}

.message .text {
	font-size: 16px;
	font-weight: 400;
	color: #666666;
}

.message .text.titlePop {
	font-weight: 600;
}

.text.titlePop.error {
	color: #e74c3c;
}
.text.titlePop.success {
	color: #3498db;
}

.toast .close {
	position: absolute;
	top: 10px;
	right: 15px;
	padding: 5px;
	cursor: pointer;
	opacity: 0.7;
}

.toast .close:hover {
	opacity: 1;
}

.toast .progress {
	position: absolute;
	bottom: 0;
	left: 0;
	height: 3px;
	width: 100%;
}

.toast .progress:before {
	content: '';
	position: absolute;
	bottom: 0;
	right: 0;
	height: 100%;
	width: 100%;
}
.toast .progress.error:before {
	background-color: #e74c3c;
}
.toast .progress.success:before {
	background-color: #3498db;
}

.progress.active:before {
	animation: progress 5s linear forwards;
}

.progress.hide:before {
	display: none;
}

@keyframes progress {
	100% {
		right: 100%;
	}
}

.toast.active ~ button {
	pointer-events: none;
}
