.horizontal-card {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	min-width: 40dvw;
	margin: 10px;
	border-radius: 10px;
	background-color: #f5f5f5;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.horizontal-card--body {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	padding: 20px;
	box-sizing: border-box;
}

.horizontal-card--parent {
	margin-top: 3dvh;
}

.horizontal-card--image {
	width: 100px;
	height: 100px;
	border-radius: 10px;
	object-fit: fill;
	margin-right: 20px;
}

.music-icon {
	width: 100px;
	height: 100px;
	border-radius: 10px;
	object-fit: cover;
}

.horizontal-card--content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	line-break: anywhere;
	align-items: flex-start;
	flex-grow: 1;
	padding: 0 10px;
	box-sizing: border-box;
}

.horizontal-card--footer {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	background-color: #eeeeee;
	border-bottom-right-radius: inherit;
	border-bottom-left-radius: inherit;
	padding: 10px;
	box-sizing: border-box;
}

.horizontal-card--date {
	font-size: 0.8rem;
	color: #666;
	display: flex;
	margin-left: 10px;
}

.horizontal-card--content--title,
.horizontal-card--content--url {
	display: flex;
	width: 100%;
	justify-content: flex-start;
	word-wrap: break-word;
	overflow: hidden;
	text-align: start;
}

.horizontal-card--content--url {
	color: #007bff;
	text-decoration: none;
	cursor: pointer;
}

.horizontal-card--content--url:hover {
	text-decoration: underline;
}
