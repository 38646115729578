.search-bar {
	align-items: center;
	display: flex;
	flex-flow: row;
	width: 80%;
	background-color: white;
	border-radius: 10px;
	margin: 60px 10% 30px 10%;
}

.search-bar input {
	width: 100%;
	padding: 12px 15px;
	margin-right: 12px;
	box-sizing: border-box;
	border: none;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	font-size: 15px;
	border-right: 2px solid #f0f0f0;
}
.search-bar [type='text']:focus {
	outline: white;
}
.search-bar button {
	border: none;
	background: none;
	margin-right: 15px;
	opacity: 0.8;

	transition: all 0.3s ease;
}
.search-bar:hover {
	cursor: pointer;
	transform: scale(1.3);
}
.search-bar:focus {
	outline: none;
}
